/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./nav-menu/nav-menu.component.ngfactory";
import * as i2 from "./nav-menu/nav-menu.component";
import * as i3 from "./_services/account.service";
import * as i4 from "./_components/alert.component.ngfactory";
import * as i5 from "./_components/alert.component";
import * as i6 from "@angular/router";
import * as i7 from "./_services/alert.service";
import * as i8 from "./app.component";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 17, "body", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-nav-menu", [], null, null, null, i1.View_NavMenuComponent_0, i1.RenderType_NavMenuComponent)), i0.ɵdid(2, 49152, null, 0, i2.NavMenuComponent, [i3.AccountService], null, null), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "alert", [], null, null, null, i4.View_AlertComponent_0, i4.RenderType_AlertComponent)), i0.ɵdid(5, 245760, null, 0, i5.AlertComponent, [i6.Router, i7.AlertService], null, null), (_l()(), i0.ɵeld(6, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(7, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 8, "div", [["class", "text-center mt-4"], ["margin", "10px"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 7, "p", [], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 6, "small", [], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Disclaimer:"])), (_l()(), i0.ɵted(-1, null, [" This app was created by Amy Ma, and designed for the use of LHS students. The software is provided "])), (_l()(), i0.ɵeld(15, 0, null, null, 1, "i", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["as is"])), (_l()(), i0.ɵted(-1, null, [" without any warranty, stated or implied."]))], function (_ck, _v) { _ck(_v, 5, 0); _ck(_v, 7, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 49152, null, 0, i8.AppComponent, [], null, null)], null, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i8.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
