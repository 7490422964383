import { Routes } from '@angular/router';
import { HomeComponent } from './home';
import { IcalendarComponent } from './icalendar/icalendar.component';
import { IcourseBlocksMapComponent } from './icourse-blocks-map/icourse-blocks-map.component';
import { CourseBlocksEditComponent } from './icourse-blocks-map/course-blocks-edit.component';
import { IlunchScheduleComponent } from './ilunch-schedule/ilunch-schedule.component';
import { AuthGuard } from './_helpers';
const ɵ0 = () => import("./users/users.module.ngfactory").then(x => x.UsersModuleNgFactory), ɵ1 = () => import("./account/account.module.ngfactory").then(x => x.AccountModuleNgFactory);
const routes = [
    //{ path: '', component: HomeComponent, pathMatch: 'full' },
    { path: '', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'calendar', component: IcalendarComponent, canActivate: [AuthGuard] },
    { path: 'courses', component: IcourseBlocksMapComponent, canActivate: [AuthGuard] },
    { path: 'courseinfo/:id', component: CourseBlocksEditComponent, canActivate: [AuthGuard] },
    { path: 'courseinfo', component: CourseBlocksEditComponent, canActivate: [AuthGuard] },
    { path: 'lunches', component: IlunchScheduleComponent, canActivate: [AuthGuard] },
    { path: 'omg/users', loadChildren: ɵ0, canActivate: [AuthGuard] },
    { path: 'account', loadChildren: ɵ1 },
    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1 };
