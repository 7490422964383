/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nav-menu.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./nav-menu.component";
import * as i5 from "../_services/account.service";
var styles_NavMenuComponent = [i0.styles];
var RenderType_NavMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavMenuComponent, data: {} });
export { RenderType_NavMenuComponent as RenderType_NavMenuComponent };
export function View_NavMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 53, "header", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 52, "nav", [["class", "navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom box-shadow mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 51, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "a", [["class", "navbar-brand"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(5, 1), (_l()(), i1.ɵted(-1, null, ["LHS Cal-23"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["aria-label", "Toggle navigation"], ["class", "navbar-toggler"], ["data-target", ".navbar-collapse"], ["data-toggle", "collapse"], ["type", "button"]], [[1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "span", [["class", "navbar-toggler-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 44, "div", [["class", "navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(11, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(12, { show: 0 }), (_l()(), i1.ɵeld(13, 0, null, null, 40, "ul", [["class", "navbar-nav flex-grow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 9, "li", [["class", "nav-item"]], null, null, null, null, null)), i1.ɵdid(15, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActiveOptions: [0, "routerLinkActiveOptions"], routerLinkActive: [1, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), i1.ɵpod(18, { exact: 0 }), i1.ɵpad(19, 1), (_l()(), i1.ɵeld(20, 0, null, null, 3, "a", [["class", "nav-link text-dark"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 21).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(21, 671744, [[2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(22, 1), (_l()(), i1.ɵted(-1, null, ["Home"])), (_l()(), i1.ɵeld(24, 0, null, null, 8, "li", [["class", "nav-item"]], null, null, null, null, null)), i1.ɵdid(25, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 3, { links: 1 }), i1.ɵqud(603979776, 4, { linksWithHrefs: 1 }), i1.ɵpad(28, 1), (_l()(), i1.ɵeld(29, 0, null, null, 3, "a", [["class", "nav-link text-dark"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 30).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(30, 671744, [[4, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(31, 1), (_l()(), i1.ɵted(-1, null, ["Calendar"])), (_l()(), i1.ɵeld(33, 0, null, null, 8, "li", [["class", "nav-item"]], null, null, null, null, null)), i1.ɵdid(34, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 5, { links: 1 }), i1.ɵqud(603979776, 6, { linksWithHrefs: 1 }), i1.ɵpad(37, 1), (_l()(), i1.ɵeld(38, 0, null, null, 3, "a", [["class", "nav-link text-dark"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 39).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(39, 671744, [[6, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(40, 1), (_l()(), i1.ɵted(-1, null, ["Courses"])), (_l()(), i1.ɵeld(42, 0, null, null, 8, "li", [["class", "nav-item"]], null, null, null, null, null)), i1.ɵdid(43, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 7, { links: 1 }), i1.ɵqud(603979776, 8, { linksWithHrefs: 1 }), i1.ɵpad(46, 1), (_l()(), i1.ɵeld(47, 0, null, null, 3, "a", [["class", "nav-link text-dark"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 48).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(48, 671744, [[8, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(49, 1), (_l()(), i1.ɵted(-1, null, ["Lunches"])), (_l()(), i1.ɵeld(51, 0, null, null, 2, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(52, 0, null, null, 1, "a", [["class", "nav-item nav-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Logout"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 5, 0, "/"); _ck(_v, 4, 0, currVal_2); var currVal_4 = "navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse"; var currVal_5 = _ck(_v, 12, 0, _co.isExpanded); _ck(_v, 11, 0, currVal_4, currVal_5); var currVal_6 = _ck(_v, 18, 0, true); var currVal_7 = _ck(_v, 19, 0, "link-active"); _ck(_v, 15, 0, currVal_6, currVal_7); var currVal_10 = _ck(_v, 22, 0, "/"); _ck(_v, 21, 0, currVal_10); var currVal_11 = _ck(_v, 28, 0, "link-active"); _ck(_v, 25, 0, currVal_11); var currVal_14 = _ck(_v, 31, 0, "/calendar"); _ck(_v, 30, 0, currVal_14); var currVal_15 = _ck(_v, 37, 0, "link-active"); _ck(_v, 34, 0, currVal_15); var currVal_18 = _ck(_v, 40, 0, "/courses"); _ck(_v, 39, 0, currVal_18); var currVal_19 = _ck(_v, 46, 0, "link-active"); _ck(_v, 43, 0, currVal_19); var currVal_22 = _ck(_v, 49, 0, "/lunches"); _ck(_v, 48, 0, currVal_22); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 4).target; var currVal_1 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = _co.isExpanded; _ck(_v, 7, 0, currVal_3); var currVal_8 = i1.ɵnov(_v, 21).target; var currVal_9 = i1.ɵnov(_v, 21).href; _ck(_v, 20, 0, currVal_8, currVal_9); var currVal_12 = i1.ɵnov(_v, 30).target; var currVal_13 = i1.ɵnov(_v, 30).href; _ck(_v, 29, 0, currVal_12, currVal_13); var currVal_16 = i1.ɵnov(_v, 39).target; var currVal_17 = i1.ɵnov(_v, 39).href; _ck(_v, 38, 0, currVal_16, currVal_17); var currVal_20 = i1.ɵnov(_v, 48).target; var currVal_21 = i1.ɵnov(_v, 48).href; _ck(_v, 47, 0, currVal_20, currVal_21); }); }
export function View_NavMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-nav-menu", [], null, null, null, View_NavMenuComponent_0, RenderType_NavMenuComponent)), i1.ɵdid(1, 49152, null, 0, i4.NavMenuComponent, [i5.AccountService], null, null)], null, null); }
var NavMenuComponentNgFactory = i1.ɵccf("app-nav-menu", i4.NavMenuComponent, View_NavMenuComponent_Host_0, {}, {}, []);
export { NavMenuComponentNgFactory as NavMenuComponentNgFactory };
