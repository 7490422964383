<h1>Hello, {{user.firstName}}!</h1>
<p>
  To help you get started, I've created a short guide below.
</p>

<p><b>1. Adding your classes:</b></p>

<ul>
  <li>Click on the tab that says <em>Courses</em>.</li>
  <li>To create a new class, simply click <em>Add a new course".</em> </li>
  <li>Enter your class name, room number, and click to select the blocks during which your class meets.</li>
  <li>When you're done, just click "Create" to add the class.</li>
  <li>This will take you back to the Course tab, where you'll see a list of your classes.</li>
  <li>You can always edit your classes simply by clicking on the class names.</li>
</ul>

<p><b>2. Adding lunches:</b></p>

<ul>
  <li>Click on the tab that says <em>Lunches</em>.</li>
  <li>Click the green <em>Edit</em> button to begin adding your lunches. </li>
  <li>To save your selections, just click <em>Submit</em> to save, or <em>Cancel</em> to reset your choices.</li>
  <li>You can edit your lunches by clicking on the <em>Edit</em> button, and save them with the <em>Save</em> button.</li>
</ul>

<p><b>3. Viewing:</b></p>

<ul>
  <li>Click on the tab that says <em>Calendar</em>.</li>
  <li>I have provided a monthly, weekly and daily view for you to see your classes, and you can navigate the days by clicking <em>Previous</em> or <em>Next</em>.</li>
</ul>

<p><b>
  I can be reached at '23ma15' with my school email.
</b>
</p>
