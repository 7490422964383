import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { delay, map, switchMap } from 'rxjs/operators';
import { CourseInfoModel, CourseInfoWithOptions, CourseDeleteInfo } from './courseinfo';

@Component({
    selector: 'app-course-blocks-edit',
    templateUrl: './course-blocks-edit.component.html',
    styleUrls: ['./course-blocks-edit.component.css']
})
/** course-blocks-edit component*/
export class CourseBlocksEditComponent implements OnInit{
  // the view title
  title: string;
  // the form model
  form: FormGroup;
  // the CourseInfoModel object to edit
  courseInfoModel: CourseInfoModel;

  //all valid block options
  candidateBlocks: Set<string>;
  selectedBlocks: string[];
  formChipControl: FormControl;

  // the CourseInfoModel object id, as fetched from the active route:
  // It's NULL when we're adding a new course,
  // and not NULL when we're editing an existing one.
  id?: number;

  /** course-blocks-edit ctor */
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) {
  }

  //course title can repeat - such as study hall
  ngOnInit() {

    this.formChipControl = new FormControl('');

    this.form = new FormGroup({
      title: new FormControl('', Validators.required),
      room: new FormControl('', Validators.required),
      blocks: new FormControl(''),
      chipsControl: this.formChipControl,
    }, null, this.isDupeCourse());

    //get individual course info with valid block options if applicable
    this.loadCourseInfoData();
  }

  loadCourseInfoData() {

    // retrieve the ID from the 'id'
    this.id = +this.activatedRoute.snapshot.paramMap.get('id');

    if (this.id) {
      // EDIT MODE
      // fetch the CourseInfoModel from the server based on CourseInfo's Id
      var url = this.baseUrl + "api/courseinfos/" + this.id;
      this.http.get<CourseInfoWithOptions>(url).subscribe(result => {
        this.courseInfoModel = result.courseInfo;
        this.candidateBlocks = result.blockOptions;
        //select blocks associated with course already
        var blocks2Select = this.courseInfoModel.blocks.split(",");
        this.formChipControl.setValue(blocks2Select);

        this.title = "Edit - " + this.courseInfoModel.title;
        // update the form with the course value
        this.form.patchValue(this.candidateBlocks);
        this.form.patchValue(this.courseInfoModel);
      }, error => console.error(error));
    }
    else {
      // ADD NEW MODE
      var courseId = 0;
      var urlOptions = this.baseUrl + "api/courseinfos/validoptions/" + courseId;
      this.http.get<Set<string>>(urlOptions).subscribe(result => {
        this.candidateBlocks = result;
        // update the form with the course value
        this.form.patchValue(this.candidateBlocks);
      }, error => console.error(error));

      this.title = "New Course";
    }
  }

  onSubmit() {

    //get current block selection & sorted
    let selectedBlockList: string = "";
    if (this.formChipControl.value.length > 0) {
      let iArraySize = this.formChipControl.value.length;
      var blockArray: string[] = new Array(iArraySize);
      for (let i = 0; i < iArraySize; i++) {
        blockArray[i] = this.formChipControl.value[i];
      }
      blockArray = blockArray.sort();
      selectedBlockList = blockArray.join(",");
    }

    let titleInput: string = this.form.get("title").value;
    let roomInput: string = this.form.get("room").value;

    if (this.id) {
      // EDIT mode
      let bChanged: boolean = false;
      //if any real changes
      if (this.courseInfoModel != null)
      {
        if (this.courseInfoModel.title != titleInput)
          bChanged = true;

        if (this.courseInfoModel.room != roomInput && !bChanged)
          bChanged = true;

        if (this.courseInfoModel.blocks != selectedBlockList && !bChanged)
          bChanged = true;
      }

      if (bChanged) {

        var courseInfoModel = <CourseInfoModel>{};
        courseInfoModel.title = titleInput;
        courseInfoModel.room = roomInput;
        courseInfoModel.blocks = selectedBlockList;
        courseInfoModel.id = this.courseInfoModel.id;

        var url = this.baseUrl + "api/courseinfos/update";

        this.http
          .post<any>(url, courseInfoModel)
          .subscribe(result => {
            console.log("CourseInfoModel " + this.id + " has been updated.");
            // go back to courses' view
            this.router.navigate(['/courses']);
          }, error => console.error(error));
      }
      else {
        // go back to courses' view
        this.router.navigate(['/courses']);
      }
    }
    else {
      // ADD NEW mode
      var url = this.baseUrl + "api/courseinfos/create";

      var courseInfoModel = <CourseInfoModel>{};
      courseInfoModel.title = titleInput;
      courseInfoModel.room = roomInput;
      courseInfoModel.blocks = selectedBlockList;
      courseInfoModel.id = 0;

      this.http
        .post<CourseInfoModel>(url, courseInfoModel)
        .subscribe(result => {

          if (result != null)
            console.log("CourseInfoModel " + result.id + " has been created.");

          // go back to Courses view
          this.router.navigate(['/courses']);
        }, error => console.error(error));
    }
  }

  onDelete() {

    if (this.id) {

      var courseDeleteInfo = <CourseDeleteInfo>{};
      courseDeleteInfo.id = this.courseInfoModel.id;

      // Delete mode
      var url = this.baseUrl + "api/courseinfos/delete";

      this.http
        .post<any>(url, courseDeleteInfo)
        .subscribe(result => {
          console.log("CourseInfo " + this.id + " has been deleted.");
          // go back to courses' view
          this.router.navigate(['/courses']);
        }, error => console.error(error));
    }
  }

  duplicateCourse() {
    //does different courses have the same block(s)
    var courseInfoModel = <CourseInfoModel>{};
    courseInfoModel.id = (this.id) ? this.id : 0;
    courseInfoModel.title = this.form.get("title").value;
    courseInfoModel.room = this.form.get("room").value;
    courseInfoModel.blocks = ""; //no need

    var url = this.baseUrl + "api/courseinfos/isdupecourse";
    return this.http.post<boolean>(url, courseInfoModel).pipe(map(result => {
      return (result ? { isDupeCourse: true } : null);
    }));
  }

  isDupeCourse(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      return of(control.value).pipe(
        delay(1000),
        switchMap(() => this.duplicateCourse().pipe(
          map(result => result ? { isDupeCourse: true } : null))));
    }
  }
}
