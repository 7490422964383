import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SysStat, User, UserDel, UserUpdate } from './../_models';

@Injectable({ providedIn: 'root' })
export class AccountService {
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;
  private baseAPIUrl: string;

  constructor(
    @Inject('BASE_URL') private baseUrl: string,
    private router: Router,
    private http: HttpClient
  ) {
    this.baseAPIUrl = baseUrl + "api";
    this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
    this.user = this.userSubject.asObservable();
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  login(username, password) {
    return this.http.post<User>(`${this.baseAPIUrl}/users/authenticate`, { username, password })
      .pipe(map(user => {
        localStorage.setItem('user', JSON.stringify(user));
        this.userSubject.next(user);
        return user;
      }));
  }

  logout() {
    localStorage.removeItem('user');
    this.userSubject.next(null);
    this.router.navigate(['/account/login']);
  }

  register(user: User) {
    return this.http.post(`${this.baseAPIUrl}/users/register`, user);
  }

  getAll() {
    return this.http.get<User[]>(`${this.baseAPIUrl}/users`);
  }

  getById(id: string) {
    return this.http.get<User>(`${this.baseAPIUrl}/users/${id}`);
  }

  getStat() {
    return this.http.get<SysStat>(`${this.baseAPIUrl}/users/stat`);
  }

  update(id, params) {
    let userInfoTmp: UserUpdate = params;
    userInfoTmp.id = +id;

    return this.http.post(`${this.baseAPIUrl}/users/updateuser`, userInfoTmp)
      .pipe(map(x => {
        if (id == this.userValue.id) {
          const user = { ...this.userValue, ...params };
          localStorage.setItem('user', JSON.stringify(user));

          this.userSubject.next(user);
        }
        return x;
      }));
  }

  delete(id: string) {
    var userInfoTmp = <UserDel>{};
    userInfoTmp.id = +id;
    return this.http.post(`${this.baseAPIUrl}/users/removeuser`, userInfoTmp)
      .pipe(map(x => {
        if (id == this.userValue.id) {
          this.logout();
        }
        return x;
      }));
  }
}
