/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./icalendar.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/angular-calendar/angular-calendar.ngfactory";
import * as i3 from "angular-calendar";
import * as i4 from "@angular/common";
import * as i5 from "./cal-custom-event-title-formatter.provider";
import * as i6 from "./cal-custom-date-formatter.provider";
import * as i7 from "./icalendar.component";
import * as i8 from "@angular/common/http";
var styles_IcalendarComponent = [i0.styles];
var RenderType_IcalendarComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_IcalendarComponent, data: {} });
export { RenderType_IcalendarComponent as RenderType_IcalendarComponent };
function View_IcalendarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mwl-calendar-month-view", [], null, [[null, "dayClicked"], [null, "beforeViewRender"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dayClicked" === en)) {
        var pd_0 = (_co.changeDay($event.day.date) !== false);
        ad = (pd_0 && ad);
    } if (("beforeViewRender" === en)) {
        var pd_1 = (_co.beforeMonthViewRender($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_CalendarMonthViewComponent_0, i2.RenderType_CalendarMonthViewComponent)), i1.ɵdid(1, 770048, null, 0, i3.CalendarMonthViewComponent, [i1.ChangeDetectorRef, i3.CalendarUtils, i1.LOCALE_ID, i3.DateAdapter], { viewDate: [0, "viewDate"], events: [1, "events"], excludeDays: [2, "excludeDays"] }, { beforeViewRender: "beforeViewRender", dayClicked: "dayClicked" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.viewDate; var currVal_1 = _co.schoolEvents; var currVal_2 = _co.excludeDays; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_IcalendarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mwl-calendar-week-view", [], null, [[null, "dayHeaderClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dayHeaderClicked" === en)) {
        var pd_0 = (_co.changeDay($event.day.date) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CalendarWeekViewComponent_0, i2.RenderType_CalendarWeekViewComponent)), i1.ɵdid(1, 770048, null, 0, i3.CalendarWeekViewComponent, [i1.ChangeDetectorRef, i3.CalendarUtils, i1.LOCALE_ID, i3.DateAdapter], { viewDate: [0, "viewDate"], events: [1, "events"], excludeDays: [2, "excludeDays"], dayStartHour: [3, "dayStartHour"], dayEndHour: [4, "dayEndHour"] }, { dayHeaderClicked: "dayHeaderClicked" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.viewDate; var currVal_1 = _co.schoolEvents; var currVal_2 = _co.excludeDays; var currVal_3 = 8; var currVal_4 = 15; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_IcalendarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mwl-calendar-day-view", [], null, null, null, i2.View_CalendarDayViewComponent_0, i2.RenderType_CalendarDayViewComponent)), i1.ɵdid(1, 49152, null, 0, i3.CalendarDayViewComponent, [], { viewDate: [0, "viewDate"], events: [1, "events"], dayStartHour: [2, "dayStartHour"], dayEndHour: [3, "dayEndHour"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.viewDate; var currVal_1 = _co.schoolEvents; var currVal_2 = 8; var currVal_3 = 15; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_IcalendarComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i3.ɵi, [i3.CalendarDateFormatter, i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 0, "link", [["href", "https://unpkg.com/bootstrap-css-only@4.4.1/css/bootstrap.min.css"], ["rel", "stylesheet"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "link", [["href", "https://unpkg.com/@fortawesome/fontawesome-free@5.15.1/css/all.css"], ["rel", "stylesheet"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "link", [["href", "https://unpkg.com/angular-calendar@0.28.26/css/angular-calendar.css"], ["rel", "stylesheet"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "link", [["href", "https://unpkg.com/flatpickr@4.6.6/dist/flatpickr.css"], ["rel", "stylesheet"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 23, "div", [["class", "row text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 10, "div", [["class", "col-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 9, "div", [["class", "btn-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "btn btn-primary"], ["mwlCalendarPreviousView", ""]], null, [[null, "viewDateChange"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("viewDateChange" === en)) {
        var pd_1 = ((_co.viewDate = $event) !== false);
        ad = (pd_1 && ad);
    } if (("viewDateChange" === en)) {
        var pd_2 = (_co.viewDateChange.next(_co.viewDate) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 16384, null, 0, i3.ɵf, [i3.DateAdapter], { view: [0, "view"], viewDate: [1, "viewDate"] }, { viewDateChange: "viewDateChange" }), (_l()(), i1.ɵted(-1, null, [" Previous "])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "btn btn-outline-secondary"], ["mwlCalendarToday", ""]], null, [[null, "viewDateChange"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("viewDateChange" === en)) {
        var pd_1 = ((_co.viewDate = $event) !== false);
        ad = (pd_1 && ad);
    } if (("viewDateChange" === en)) {
        var pd_2 = (_co.viewDateChange.next(_co.viewDate) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 16384, null, 0, i3.ɵh, [i3.DateAdapter], { viewDate: [0, "viewDate"] }, { viewDateChange: "viewDateChange" }), (_l()(), i1.ɵted(-1, null, [" Today "])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "btn btn-primary"], ["mwlCalendarNextView", ""]], null, [[null, "viewDateChange"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("viewDateChange" === en)) {
        var pd_1 = ((_co.viewDate = $event) !== false);
        ad = (pd_1 && ad);
    } if (("viewDateChange" === en)) {
        var pd_2 = (_co.viewDateChange.next(_co.viewDate) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 16384, null, 0, i3.ɵg, [i3.DateAdapter], { view: [0, "view"], viewDate: [1, "viewDate"] }, { viewDateChange: "viewDateChange" }), (_l()(), i1.ɵted(-1, null, [" Next "])), (_l()(), i1.ɵeld(17, 0, null, null, 3, "div", [["class", "col-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(19, null, [" ", " "])), i1.ɵppd(20, 5), (_l()(), i1.ɵeld(21, 0, null, null, 7, "div", [["class", "col-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 6, "div", [["class", "btn-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 1, "div", [["class", "btn btn-primary"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.updateCalendarView(_co.CalendarView.Month) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Month "])), (_l()(), i1.ɵeld(25, 0, null, null, 1, "div", [["class", "btn btn-primary"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.updateCalendarView(_co.CalendarView.Week) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Week "])), (_l()(), i1.ɵeld(27, 0, null, null, 1, "div", [["class", "btn btn-primary"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.updateCalendarView(_co.CalendarView.Day) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Day "])), (_l()(), i1.ɵeld(29, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 7, "div", [], null, null, null, null, null)), i1.ɵdid(31, 16384, null, 0, i4.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IcalendarComponent_1)), i1.ɵdid(33, 278528, null, 0, i4.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i4.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IcalendarComponent_2)), i1.ɵdid(35, 278528, null, 0, i4.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i4.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IcalendarComponent_3)), i1.ɵdid(37, 278528, null, 0, i4.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i4.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.view; var currVal_1 = _co.viewDate; _ck(_v, 9, 0, currVal_0, currVal_1); var currVal_2 = _co.viewDate; _ck(_v, 12, 0, currVal_2); var currVal_3 = _co.view; var currVal_4 = _co.viewDate; _ck(_v, 15, 0, currVal_3, currVal_4); var currVal_9 = _co.view; _ck(_v, 31, 0, currVal_9); var currVal_10 = "month"; _ck(_v, 33, 0, currVal_10); var currVal_11 = "week"; _ck(_v, 35, 0, currVal_11); var currVal_12 = "day"; _ck(_v, 37, 0, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = i1.ɵunv(_v, 19, 0, _ck(_v, 20, 0, i1.ɵnov(_v, 0), _co.viewDate, (_co.view + "ViewTitle"), "en", _co.weekStartsOn, _co.excludeDays)); _ck(_v, 19, 0, currVal_5); var currVal_6 = (_co.view === _co.CalendarView.Month); _ck(_v, 23, 0, currVal_6); var currVal_7 = (_co.view === _co.CalendarView.Week); _ck(_v, 25, 0, currVal_7); var currVal_8 = (_co.view === _co.CalendarView.Day); _ck(_v, 27, 0, currVal_8); }); }
export function View_IcalendarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-icalendar", [], null, null, null, View_IcalendarComponent_0, RenderType_IcalendarComponent)), i1.ɵprd(4608, null, i3.CalendarEventTitleFormatter, i5.CustomEventTitleFormatter, [i1.LOCALE_ID]), i1.ɵprd(4608, null, i3.CalendarDateFormatter, i6.CustomDateFormatter, [i1.LOCALE_ID, i3.DateAdapter]), i1.ɵdid(3, 114688, null, 0, i7.IcalendarComponent, [i8.HttpClient, "BASE_URL"], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var IcalendarComponentNgFactory = i1.ɵccf("app-icalendar", i7.IcalendarComponent, View_IcalendarComponent_Host_0, {}, {}, []);
export { IcalendarComponentNgFactory as IcalendarComponentNgFactory };
