import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common/http";
export class AccountService {
    constructor(baseUrl, router, http) {
        this.baseUrl = baseUrl;
        this.router = router;
        this.http = http;
        this.baseAPIUrl = baseUrl + "api";
        this.userSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('user')));
        this.user = this.userSubject.asObservable();
    }
    get userValue() {
        return this.userSubject.value;
    }
    login(username, password) {
        return this.http.post(`${this.baseAPIUrl}/users/authenticate`, { username, password })
            .pipe(map(user => {
            localStorage.setItem('user', JSON.stringify(user));
            this.userSubject.next(user);
            return user;
        }));
    }
    logout() {
        localStorage.removeItem('user');
        this.userSubject.next(null);
        this.router.navigate(['/account/login']);
    }
    register(user) {
        return this.http.post(`${this.baseAPIUrl}/users/register`, user);
    }
    getAll() {
        return this.http.get(`${this.baseAPIUrl}/users`);
    }
    getById(id) {
        return this.http.get(`${this.baseAPIUrl}/users/${id}`);
    }
    getStat() {
        return this.http.get(`${this.baseAPIUrl}/users/stat`);
    }
    update(id, params) {
        let userInfoTmp = params;
        userInfoTmp.id = +id;
        return this.http.post(`${this.baseAPIUrl}/users/updateuser`, userInfoTmp)
            .pipe(map(x => {
            if (id == this.userValue.id) {
                const user = Object.assign({}, this.userValue, params);
                localStorage.setItem('user', JSON.stringify(user));
                this.userSubject.next(user);
            }
            return x;
        }));
    }
    delete(id) {
        var userInfoTmp = {};
        userInfoTmp.id = +id;
        return this.http.post(`${this.baseAPIUrl}/users/removeuser`, userInfoTmp)
            .pipe(map(x => {
            if (id == this.userValue.id) {
                this.logout();
            }
            return x;
        }));
    }
}
AccountService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountService_Factory() { return new AccountService(i0.ɵɵinject("BASE_URL"), i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.HttpClient)); }, token: AccountService, providedIn: "root" });
