<link href="https://unpkg.com/bootstrap-css-only@4.4.1/css/bootstrap.min.css" rel="stylesheet">
<link href="https://unpkg.com/@fortawesome/fontawesome-free@5.15.1/css/all.css" rel="stylesheet">
<link href="https://unpkg.com/angular-calendar@0.28.26/css/angular-calendar.css" rel="stylesheet">
<link href="https://unpkg.com/flatpickr@4.6.6/dist/flatpickr.css" rel="stylesheet">

<!--<p *ngIf="!schoolEvents"><em>Loading...</em></p>-->
<!--*ngIf="schoolEvents"-->
<div class="row text-center">
  <div class="col-md-4">
    <div class="btn-group">
      <div class="btn btn-primary"
           mwlCalendarPreviousView
           [view]="view"
           [(viewDate)]="viewDate"
           (viewDateChange)="viewDateChange.next(viewDate)">
        Previous
      </div>
      <div class="btn btn-outline-secondary"
           mwlCalendarToday
           [(viewDate)]="viewDate"
           (viewDateChange)="viewDateChange.next(viewDate)">
        Today
      </div>
      <div class="btn btn-primary"
           mwlCalendarNextView
           [view]="view"
           [(viewDate)]="viewDate"
           (viewDateChange)="viewDateChange.next(viewDate)">
        Next
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <h3>
      {{  viewDate | calendarDate:(view + 'ViewTitle'):'en':weekStartsOn:excludeDays  }}
    </h3>
  </div>
  <div class="col-md-4">
    <div class="btn-group">
      <div class="btn btn-primary"
           (click)="updateCalendarView(CalendarView.Month)"
           [class.active]="view === CalendarView.Month">
        Month
      </div>
      <div class="btn btn-primary"
           (click)="updateCalendarView(CalendarView.Week)"
           [class.active]="view === CalendarView.Week">
        Week
      </div>
      <div class="btn btn-primary"
           (click)="updateCalendarView(CalendarView.Day)"
           [class.active]="view === CalendarView.Day">
        Day
      </div>
    </div>
  </div>
</div>
<br />

<div [ngSwitch]="view">
  <mwl-calendar-month-view *ngSwitchCase="'month'"
                           [viewDate]="viewDate"
                           [events]="schoolEvents"
                           [excludeDays]="excludeDays"
                           (dayClicked)="changeDay($event.day.date)"
                           (beforeViewRender)="beforeMonthViewRender($event)">
  </mwl-calendar-month-view>

  <mwl-calendar-week-view *ngSwitchCase="'week'"
                          [viewDate]="viewDate"
                          [events]="schoolEvents"
                          [excludeDays]="excludeDays"
                          [dayStartHour]="8"
                          [dayEndHour]="15"
                          (dayHeaderClicked)="changeDay($event.day.date)">
  </mwl-calendar-week-view>

  <mwl-calendar-day-view *ngSwitchCase="'day'"
                         [viewDate]="viewDate"
                         [events]="schoolEvents"
                         [dayStartHour]="8"
                         [dayEndHour]="15">
  </mwl-calendar-day-view>
</div>
