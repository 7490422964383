import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { LunchSchedule } from './lunchschedule';

@Component({
    selector: 'app-ilunch-schedule',
    templateUrl: './ilunch-schedule.component.html',
    styleUrls: ['./ilunch-schedule.component.css']
})
/** ilunch-schedule component*/
export class IlunchScheduleComponent implements OnInit {
  /** ilunch-schedule ctor */

  // the view title
  title: string;
  // the form model
  form: FormGroup;

  lunchSchedule: LunchSchedule;
  blocks2Select: string[];

  // the LunchInfo object id, as fetched from the active route:
  // It's NULL when we don't have a lunch schedule yet
  // and not NULL when we're editing an existing one.
  id?: number;

  bIsEditingSchedule: boolean = false;

  //set up lunch option form
  schoolDays = [0, 1, 2, 3, 4, 5];
  schoolDayLabels: string[] = ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6'];
  lunchOptions = new Set(['Lunch 1', 'Lunch 2', 'Lunch 3', 'Lunch 4']);
  formControls!: Array<FormControl>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) {
  }

  //course title can repeat - such as study hall
  ngOnInit() {

    this.title = "Input Lunches";

    this.formControls = [new FormControl(['']), new FormControl(['']),
    new FormControl(['']), new FormControl(['']), new FormControl(['']),
    new FormControl([''])];

    this.form = new FormGroup({
      day1: this.formControls[0],
      day2: this.formControls[1],
      day3: this.formControls[2],
      day4: this.formControls[3],
      day5: this.formControls[4],
      day6: this.formControls[5],
    });

    this.loadLunchShcedule();
  }

  loadLunchShcedule() {
    //get data from server on currently selection
    var url = this.baseUrl + "api/lunchschedule";
    this.http.get<LunchSchedule>(url).subscribe(result => {

      if (result !== null && result !== undefined) {
        //get course info
        this.lunchSchedule = result;

        //set id
        this.id = this.lunchSchedule.id;

        //get existing lunch schedule from server
        this.title = "Lunches";

        //select blocks associated with course already
        this.blocks2Select = this.lunchSchedule.schedules.split(",");
        if (this.blocks2Select.length == 6) {
          for (let i = 0; i < 6; i++) {
            if (this.blocks2Select[i] !== null && this.blocks2Select[i] !== undefined && this.blocks2Select[i] != "") {
              this.formControls[i].setValue(this.blocks2Select[i]);
            }
          }
        }
        // update the form with the course value
        this.form.patchValue(this.lunchSchedule);
      }

      this.onEndEditing();

    }, error => console.error(error));
  }

  onSubmit() {

    //get lunch choices currently shown
    let lunchSelection: string = "";
    for (let i = 0; i < 6; i++) {
      lunchSelection += (this.formControls[i].value !== null && this.formControls[i].value !== undefined) ? this.formControls[i].value : "";
      if (i != 5) {
        lunchSelection += ",";
      }
    }

    if (this.id) {
      // EDIT mode
      if (this.lunchSchedule.schedules != lunchSelection) {
        //changed
        this.lunchSchedule.schedules = lunchSelection;

        var url = this.baseUrl + "api/lunchschedule/update";

        this.http
          .post<LunchSchedule>(url, this.lunchSchedule)
          .subscribe(result => {
            console.log("LunchSchedule " + this.id + " has been updated.");
          }, error => console.error(error));

      } else {
        //no change
        console.log("LunchSchedule " + this.id + " no change to be updated.");
      }
    }
    else {
      // ADD NEW mode
      var lunchSchedule = <LunchSchedule>{};
      lunchSchedule.schedules = lunchSelection;
      lunchSchedule.id = 0;

      var url = this.baseUrl + "api/lunchschedule/create";

      this.http
        .post<LunchSchedule>(url, lunchSchedule)
        .subscribe(result => {
          console.log("LunchSchedule " + result.id + " has been created.");
        }, error => console.error(error));
    }

    this.onEndEditing();
  }

  onCancel() {

    //check if lunch choices changes
    let lunchSelection: string = "";
    for (let i = 0; i < 6; i++) {
      lunchSelection += (this.formControls[i].value !== null && this.formControls[i].value !== undefined) ? this.formControls[i].value : "";
      if (i != 5) {
        lunchSelection += ",";
      }
    }

    if (this.id && this.lunchSchedule) {
      if (lunchSelection != this.lunchSchedule.schedules) {
        if (this.blocks2Select.length == 6) {
          for (let i = 0; i < 6; i++) {
            if (this.blocks2Select[i] !== null && this.blocks2Select[i] !== undefined && this.blocks2Select[i] != "") {
              this.formControls[i].setValue(this.blocks2Select[i]);
            }
          }
        }

        // update the form with the course value
        this.form.patchValue(this.lunchSchedule);
      }
    }
    else {
      //clean up locally
      if (lunchSelection != ",,,,,") {
        for (let i = 0; i < 6; i++) {
          this.formControls[i].setValue("");
        }
      }
    }

    this.onEndEditing();
  }

  onStartEditing() {
    if (this.id) {
      this.title = "Edit Lunches";
    } else {
      this.title = "New Lunches";
    }
    
    this.bIsEditingSchedule = true;
    for (let i = 0; i < 6; i++)
    {
      this.formControls[i].enable();
    }
  }

  onEndEditing() {
    this.title = "Lunches";
    this.bIsEditingSchedule = false;
    for (let i = 0; i < 6; i++) {
      this.formControls[i].disable();
    }
  }
}
