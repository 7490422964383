<body>
  <app-nav-menu></app-nav-menu>
  <div class="container">
    <alert></alert>
    <router-outlet></router-outlet>
  </div>


  <br />
  <!-- credits -->
  <div class="text-center mt-4" margin="10px">
    <p><small><b>Disclaimer:</b> This app was created by Amy Ma, and designed for the use of LHS students.  The software is provided <i>as is</i> without any warranty, stated or implied.</small></p>
  </div>
</body>
