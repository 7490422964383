<h1>Courses</h1>

<p>Courses are listed below. Click on the course name to edit.</p>

<p *ngIf="!courseInfos"><em>Loading...</em></p>

<div class="commands text-right" *ngIf="courseInfos">
  <button type="submit"
          [routerLink]="['/courseinfo']"
          class="btn btn-success">
    New Course
  </button>
</div>
<br />
<table mat-table [dataSource]="courseInfos" class="table-striped mat-elevation-z8" [hidden]="!courseInfos"
       #sort
       matSort (matSortChange)="loadData()"
       matSortActive="{{defaultSortColumn}}" matSortDirection="{{defaultSortOrder}}">


  <!-- Title Column -->
  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
    <td mat-cell *matCellDef="let courseInfo">
      <a [routerLink]="['/courseinfo', courseInfo.id]">{{courseInfo.title}}</a>
    </td>
  </ng-container>

  <!-- Room Column -->
  <ng-container matColumnDef="room">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Room</th>
    <td mat-cell *matCellDef="let courseInfo"> {{courseInfo.room}} </td>
  </ng-container>

  <!-- Blocks Column -->
  <ng-container matColumnDef="blocks">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Mapped Blocks</th>
    <td mat-cell *matCellDef="let courseInfo"> {{courseInfo.blocks}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
﻿
<!-- Pagination directive -->
<mat-paginator [hidden]="!courseInfos"
               #paginator
               (page)="getData($event)"
               [pageSize]="10"
               [pageSizeOptions]="[10, 20, 50]"
               showFirstLastButtons></mat-paginator>
